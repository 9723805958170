<template>
  <div v-if="!isLoading">
    <vue-headful :title="pageTitle" />
    <section slot="" v-if="(report || reportFromId) && (canViewDocument || isStaff)">
      <pdf-renderer
        :isDocument="false"
      />
    </section>
    <section  v-else-if="(report || reportFromId) && (!canViewDocument && !isStaff)">
      <div id="body-content-area-all">
        <div class="has-text-centered heading" style="background-color: #ededed;">
          <h1 class="is-size-6" style="padding: 10px 0px">Could Not Access Report</h1>
        </div>
        <div id="pagelayout" style="height: 100%!important; scrollbar-width: thin!important; position: static !important;">
          <p>Not permitted to view report.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import PDFRenderer from './PDFRenderer.vue'

export default {
  components: {
    'pdf-renderer': PDFRenderer
  },
  props: {
    report: {
      required: false
    }
  },
  data () {
    return {
      isLoaded: {},
      xhr: new XMLHttpRequest(),
      tryRenderingIE: false,
      institution: null,
      reportFromId: null,
      canViewDocument: false,
      isLoading: true,
      shouldShowLoading: true,
      numPages: null,
      src: null,
      rotate: 0,
      isPrinting: false,
      isEdgeOrSafari: false,
      isSafari: false,
      ua: '',
      isRotating: false
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      let name = this.report ? this.report.displayName : 'Report'

      if (this.institution) {
        return (name + ' - ' + this.institution.name + ' ' + (parseInt(this.activeSite.id) === 1 ? 'BOLI Portal' : 'COLI Portal'))
      }

      return name
    },
    extension () {
      if (this.report && this.report.extension.toLowerCase() !== '.pdf') {
        return this.report.extension.toLocaleUpperCase()
      }

      if (this.reportFromId && this.reportFromId.extension.toLowerCase() !== '.pdf') {
        return this.reportFromId.extension.toLocaleUpperCase()
      }

      return ''
    },
    isStaff () {
      return ['staff', 'manager'].includes(this.clientSession.userRole.toLowerCase())
    }
  },
  watch: {
    '$route.params.id': function (id) {
      this.getReport(id)
    }
  },
  mounted () {
    this.getReport(this.$route.params.id)

    this.report = null

    if (!['Staff', 'Manager'].includes(this.clientSession.userRole)) {
      this.GetInstitution()
    }
  },
  methods: {
    async checkUserPermissions () {
      let permissions = await this.getReportTypes()

      if (permissions.includes(this.reportFromId ? this.reportFromId.typeCode : this.report.typeCode)) {
        this.canViewDocument = true
      }

      this.isLoading = false
    },
    async getReportTypes () {
      let response = await this.axios.get(`/memberships/${this.clientSession.userId}`)
      if (response.status === 200) {
        return response.data.reportTypeCodes
      }

      return []
    },
    async getReport (id) {
      let response = await this.axios.get('/reports/' + id)

      if (response.status >= 200 && response.status < 300) {
        this.reportFromId = response.data
        this.checkUserPermissions()
      }
    },
    async GetInstitution () {
      try {
        let that = this
        this.isLoading = true
        let route = `/institutions/${this.clientSession.institutionId}/`
        this.axios.get(route)
          .then(function (response) {
            if (response.status >= 200 && response.status < 300) {
              that.institution = response.data
            }
          })
      } catch (error) {
        this.handleApiErr(error)
      }
    }
  }
}
</script>

<style scoped>
#loading-container {
  background-color: white !important;
}
#pagelayout {
  width: 100%;
}

@media (max-width: 799px) {
  #MyAccount {
    display: none;
  }
}
</style>

<style scoped>
@media  (max-width: 850px) {
  .heading {
    text-align: right;
  }

  .container-to-the-report-container {
    padding: 0rem!important;
  }

  .heading {
    width: 80%!important;
    transform: translateX(0px);
  }

  #body-content-area-all {
    overflow-y: hidden;
    border: 1px solid rgb(237, 237, 237);
    padding: 0rem!important;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
    background-color: whitesmoke!important;
    overflow: scroll;
  }

  #pagelayout {
    padding: 0px!important;
    background-color: whitesmoke;
    width: 100%;
  }
}

.heading {
  position: relative;
  width: 90%!important;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.pdf-page {
  width: 100%;
  aspect-ratio: 3/22!important;
}

.pdf-controls {
  position: absolute;
  left: 0;
  top: 0;

  padding: .25rem .5rem;
}

.pdf-controls > button, .pdf-controls > a.download {
  padding-top: .5rem!important;
  padding-bottom: .4rem!important;
}

.pdf-controls > button[disabled], .pdf-controls > a[disabled] {
  opacity: .5;
  color: #b0b0b0!important;
  border: 1px solid #b0b0b0!important;
  background: rgba(0,0,0,0.05);
  cursor: pointer;
}

.pdf-controls > button, .pdf-controls > a {
  position: sticky;

  margin: 0;
  margin-right: .5rem;
  margin-top: .175rem;

  border: 1px solid #b0b0b0;
  border-radius: .25rem;

  color: #b0b0b0;

  padding: .125rem!important;
  padding-right: .25rem!important;

  cursor: pointer;

  background: transparent;
}

.pdf-controls > button:hover, .pdf-controls > a:hover {
  background-color: rgba(0,0,0,0.05);
  color: rgba(0,0,0,0.5);

  transition: all .25s ease-in-out;
}

.container-to-the-report-container {
  padding: 0rem 5rem;
}

.report-container {
  height: 100%;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-content: center;
  align-items: center;

  overflow: hidden;
  padding: .5rem;
  cursor: pointer;

  margin: 0 auto;

  background-color: whitesmoke;
  border-left: 1px solid rgb(237, 237, 237);
  border-right: 1px solid rgb(237, 237, 237);
}

.-pdf {
  height: auto;
  width: 100%;
  background-color: white;

  border: 2px solid rgb(237, 237, 237);
  border-radius: .25rem;
}

.-pdf > canvas {
  max-height: 100%!important;
}

#app-body-area[activelayout="client-facing"] #body-content-area-all[pdfkit] {
  height: calc(100vh - 86px - 92px)!important;
}

.modal-background {
  z-index: 40;
}

.modal-card {
  z-index: 50;
}
#app-body-area[activelayout="osr-facing"] #body-content-area-all[pdfkit] {
  height: calc(100vh - 52px - 86px - 92px)!important;
}

#app-body-area[activelayout="osr-facing"] #body-content-area-all, #app-body-area[activelayout="client-facing"] #body-content-area-all {
  margin: 0!important;
  width: 99%;
  padding: 1rem;
}

.is-rotating {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        opacity: 5%;
    }

    50% {
      opacity: 30%;
    }

    100% {
        opacity: 5%;
    }
}
</style>
